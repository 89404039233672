import React from 'react'
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl'
import { compose } from 'recompose'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import withPageContext from '../pageContext'
import withLayout from '../layout'

import "../styles/download.sass"

// import catalogPdf from "../../static/download/katalog_produktu.pdf"
// import stpFile from "../../static/download/vl180a.zip"

export const query = graphql`
    query {
        pdfIcon: file(relativePath: { eq: "fileIcons/pdf.png" }) {
            childImageSharp {
                fixed(width: 50, height: 50) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        vl180a: file(relativePath: { eq: "vl180a.zip" }) {
            id
            publicURL
        }
        catalogPdf: file(relativePath: { eq: "katalog_produktu.pdf" }) {
            id
            publicURL
        }
        kruhovy_pohon_trans: file(relativePath: { eq: "products/kruhovy_pohon_trans.png" }) {
            childImageSharp {
                fixed(height: 328, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 600, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        linearni_pohon_trans: file(relativePath: { eq: "products/linearni_pohon_trans.png" }) {
            childImageSharp {
                fixed(height: 328, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 600, maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        models: allFile(filter: {relativePath: {regex: "/^models/"}}) {
            edges {
              node {
                relativePath
                publicURL
                name
              }
            }
          }
    }
`

const DownloadPage = ({ data, intl }) => {
    const models = data.models.edges
    console.log(models)

    const getModel = name => {
        return models.find(item => item.node.name === name).node.publicURL
    }

    console.log(getModel("VD-30"))

    return (
        <React.Fragment>
            <SEO title={intl.formatMessage({ id: 'download.title' })} />
            <main id="download">
                <section className="page-section pt-5">
                    <div className="container ">
                        <div className="row">
                            <div className="col">
                                <h3 className="pb-2"><FormattedHTMLMessage id="download.catalog" /></h3>
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                <a
                                    className="card text-center"
                                    href={data.catalogPdf.publicURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Img
                                        fixed={data.pdfIcon.childImageSharp.fixed}
                                        className="card-img-top mx-auto mt-3"
                                        alt=""
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <FormattedHTMLMessage id="download.productsCatalog" />
                                        </h5>
                                        {/* <p className="card-text text-muted">PDF obsahuje katalog produktů firmy Advateq s.r.o.</p> */}
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="py-4">
                            <div className="row">
                                <div className="col">
                                    <h3 className="pb-2"><FormattedHTMLMessage id="download.tab1.title" /></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col"><FormattedHTMLMessage id="download.tab.type" /></th>
                                                <th scope="col"><FormattedHTMLMessage id="download.tab.diameter" /></th>
                                                <th scope="col"><FormattedHTMLMessage id="download.tab.model" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <th scope="row">VD-15</th>
                                                <td>150</td>
                                                <td><a className="download-link" href={getModel("VD-15")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">VD-20</th>
                                                <td>200</td>
                                                <td><a className="download-link" href={getModel("VD-20")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">VD-25</th>
                                                <td>250</td>
                                                <td><a className="download-link" href={getModel("VD-25")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">VD-30</th>
                                                <td>300</td>
                                                <td><a className="download-link" href={getModel("VD-30")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">VD-40</th>
                                                <td>400</td>
                                                <td><a className="download-link" href={getModel("VD-40")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">VD-50</th>
                                                <td>500</td>
                                                <td><a className="download-link" href={getModel("VD-50")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">VD-60</th>
                                                <td>600</td>
                                                <td><a className="download-link" href={getModel("VD-60")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <Img
                                        fixed={data.kruhovy_pohon_trans.childImageSharp.fixed}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            <div className="row">
                                <div className="col">
                                    <h3 className="pb-2"><FormattedHTMLMessage id="download.tab2.title" /></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col"><FormattedHTMLMessage id="download.tab.type" /></th>
                                                <th scope="col"><FormattedHTMLMessage id="download.tab.width" /></th>
                                                <th scope="col"><FormattedHTMLMessage id="download.tab.model" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <th scope="row">VL-35</th>
                                                <td>35</td>
                                                <td><a className="download-link" href={getModel("VL-35")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">VL-50</th>
                                                <td>50</td>
                                                <td><a className="download-link" href={getModel("VL-50")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">VL-80</th>
                                                <td>80</td>
                                                <td><a className="download-link" href={getModel("VL-80")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">VL-80L</th>
                                                <td>80</td>
                                                <td><a className="download-link" href={getModel("VL-80L")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">VL-180</th>
                                                <td>180</td>
                                                <td><a className="download-link" href={getModel("VL-180")} download><FormattedHTMLMessage id="download.download" /></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <Img
                                        fixed={data.linearni_pohon_trans.childImageSharp.fixed}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="row justify-content-center section-header">
                            <div className="text-center">
                                <h1>Ke stažení</h1>
                                <hr className="divider light my-4" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2">
                                <a className="card text-center" href={data.catalogPdf.publicURL} target="_blank">
                                    <Img
                                        fixed={data.pdfIcon.childImageSharp.fixed}
                                        className="card-img-top mx-auto mt-3"
                                        alt=""
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">Katalog produktů</h5>
                                        <p className="card-text text-muted">PDF obsahuje katalog produktů firmy Advateq s.r.o.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-2">
                                <a className="card text-center" href={data.vl180a.publicURL} target="_blank" download> 
                                    <Img
                                        fixed={data.zipIcon.childImageSharp.fixed}
                                        className="card-img-top mx-auto mt-3"
                                        alt=""
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">STP</h5>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

DownloadPage.propTypes = {
    intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(DownloadPage)
